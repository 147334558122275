/* Global imports */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Toolbar, IconButton, Typography, AppBar, Button } from '@mui/material';
import clsx from 'clsx';

/* Icon imports */
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import BugReportIcon from '@mui/icons-material/BugReport';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WifiOffIcon from '@mui/icons-material/WifiOff';

/* Local imports */
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { toggleDrawer } from '../../slices/sessionSlice';
import DrawerMenu from '../DrawerMenu';
import { disableDebugMode } from '../../utils/misc';
import navBarStyles from './styles';

type NavBarProps = {
  returnButton?: boolean;
  headerTitle?: string;
  to?: string;
  returnFunction?: () => void;
  closeFunction?: () => void;
  hideMenuButton?: boolean;
  confirmNavigation?: boolean;
  isLogged?: boolean;
  style?: any;
  className?: string;
  classes?: {
    toolbar?: string;
  };
};

function NavBar(props: NavBarProps) {
  const { className, classes } = props;
  const localClasses = navBarStyles();
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(state => state.session.online);
  const debugMode = useAppSelector(state => state.session.debugMode);
  /* Functions */
  function handleClickBack() {
    if (!!props?.returnFunction) props.returnFunction();
  }
  /* Return Component */
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(localClasses.appBar, className)}
        style={{ ...props.style, height: isOnline ? 64 : 96 }}
      >
        {!isOnline && (
          <div className={localClasses.alert}>
            <WifiOffIcon style={{ margin: 0, fontSize: 16 }} />
            <Typography fontSize={10}>
              Sem conexão. Os documentos serão sincronizados quando o sinal
              voltar.
            </Typography>
          </div>
        )}

        <Toolbar className={clsx(localClasses.toolBar, classes?.toolbar)}>
          {props.returnButton && (
            <div style={{ marginLeft: 10 }}>
              <NavLink className={localClasses.backButton} to={props.to || '#'}>
                <IconButton
                  edge="start"
                  id="btn-main-back"
                  className={localClasses.backButton}
                  aria-label="menu"
                  onClick={handleClickBack}
                >
                  <ArrowBackRoundedIcon
                    style={{ fontSize: 22, color: 'inherit' }}
                  />
                </IconButton>
              </NavLink>
            </div>
          )}

          <div className={localClasses.title}>
            {!!debugMode && (
              <BugReportIcon
                onClick={() => {
                  disableDebugMode();
                }}
              />
            )}
            <Typography variant="h6" fontSize={14} paddingTop="2px">
              {!debugMode ? props?.headerTitle || '' : 'Modo Debug'}
            </Typography>
          </div>

          {!!props.closeFunction ? (
            <IconButton
              onClick={props.closeFunction}
              edge="start"
              className={localClasses.closeButton}
              aria-label="close"
            >
              <CloseRoundedIcon style={{ fontSize: 22, color: 'inherit' }} />
            </IconButton>
          ) : (
            !props.hideMenuButton && (
              <Button
                id="btn-navbar"
                onClick={() => dispatch(toggleDrawer())}
                variant="contained"
                className={localClasses.menuButton}
                color="inherit"
                aria-label="menu"
              >
                <MenuIcon style={{ fontSize: 32 }} />
              </Button>
            )
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <DrawerMenu
        isLogged={!!props.isLogged}
        confirmNavigation={props.confirmNavigation}
      />
      {!isOnline && <div className={localClasses.alertDivPadding}>.</div>}
    </React.Fragment>
  );
}

export default NavBar;
