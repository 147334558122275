import { Dispatch, useEffect, useRef, useState } from 'react';

type SetStateRefAction<S> = S | ((prevState: S) => S);

/**
 * A hook to wrap a useState inside a useRef
 * This is useful to use states inside of event listeners
 * @param initialState the initial value of the state
 * @returns an array with a ref and a function to set a new state
 * @author Douglas Flores
 */
export function useStateRef<S>(
  initialState: S
): [React.MutableRefObject<S>, Dispatch<SetStateRefAction<S>>] {
  const [state, setState] = useState<S>(initialState);
  const stateRef = useRef(state);
  const setStateRef = (payload: SetStateRefAction<S>) => {
    if (payload instanceof Function) stateRef.current = payload(state);
    else stateRef.current = payload;
    setState(payload);
  };

  return [stateRef, setStateRef];
}

/**
 * A hook to count in real time the elapsed time since a startDatetime
 * @param startDatetime the datetime since the counter should be running
 * @returns a time string
 * @author Douglas Flores
 */
export function useElapsedTime(startDatetime?: string): [string, number] {
  const baseline = startDatetime ?? new Date().toISOString();
  const [elapsedTimeStr, setElapsedTimeStr] = useState<string>('00:00:00');
  const [elapsedTimeInSec, setElapsedTimeInSec] = useState<number>(0);
  function updateElapsedTime() {
    const time = Date.now() - Date.parse(baseline);
    setElapsedTimeInSec(Math.floor(time / 1000));
    const hours = `${Math.floor(time / (1000 * 60 * 60))}`.padStart(2, '0');
    const minutes = `${Math.floor((time / 1000 / 60) % 60)}`.padStart(2, '0');
    const seconds = `${Math.floor((time / 1000) % 60)}`.padStart(2, '0');
    setElapsedTimeStr(`${hours}:${minutes}:${seconds}`);
  }
  useEffect(() => {
    updateElapsedTime();
    const interval = setInterval(() => updateElapsedTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return [elapsedTimeStr, elapsedTimeInSec];
}

interface UseAsyncFunction {
  isLoading: boolean;
  run: () => Promise<any>;
}
/**
 * Hook to run an asynchronous function and get a flag to signal if the function is running or not
 * @param fn asynchronous function to be executed
 * @returns a function runner and a boolean flag indicating if the function is running or not
 * @author Douglas Flores
 */
export function useAsyncFunction(fn: () => Promise<any>): UseAsyncFunction {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  async function run() {
    setIsLoading(true);
    try {
      await fn();
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  }
  return { isLoading, run };
}
