import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import theme from '../../theme';
import useStyles from '../../useStyles';

export type RouterPromptProps = {
  title: string;
  body?: string;
  onOk?: () => void;
  okText?: string;
  cancelText?: string;
  when?: boolean | ((pathname: string) => boolean);
};

export function RouterPrompt(props: RouterPromptProps) {
  const { when, title, body, onOk, okText, cancelText } = props;
  const classes = useStyles();
  /* Hooks */
  const history = useHistory();
  /* States */
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [currentAction, setCurrentAction] = useState<
    'PUSH' | 'POP' | 'REPLACE'
  >();
  /* Refs */
  const unblockRef = React.useRef<any>();
  /* Functions */
  const handleOk = () => {
    if (unblockRef) unblockRef.current();
    if (!!onOk) onOk();
    setShowPrompt(false);
    switch (currentAction) {
      case 'POP':
        history.replace(currentPath);
        history.goBack();
        break;
      case 'REPLACE':
        history.replace(currentPath);
        break;
      default:
        history.push(currentPath);
        break;
    }
  };
  const handleCancel = () => setShowPrompt(false);
  /* useEffects */
  useEffect(() => {
    unblockRef.current = history.block((location, action) => {
      if (!!when) {
        if (typeof when === 'boolean' || when(location.pathname)) {
          setCurrentPath(location.pathname);
          setCurrentAction(action);
          setShowPrompt(true);
          return false;
        } else {
          setShowPrompt(false);
          return undefined;
        }
      }
      return 'true';
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [history, when]);
  /* Return */
  return showPrompt ? (
    <Dialog
      open={showPrompt}
      onClose={() => setShowPrompt(false)}
      slotProps={{ backdrop: { className: classes.dialogBackdrop } }}
      PaperProps={{ style: { borderRadius: 5 } }}
    >
      <DialogContent>
        <Typography variant="h4" marginBottom={2} marginTop={0}>
          {title}
        </Typography>
        {!!body?.length && (
          <Typography variant="body2" color={theme.palette.grey[600]}>
            {body}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelText ?? 'Não'}</Button>
        <Button onClick={handleOk} variant="contained">
          {okText ?? 'Sim'}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}
