/* Global imports */
import React, { useEffect, useMemo, useState } from 'react';
import {
  SwipeableDrawer,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TextField,
  Button,
  Typography,
  ListItemButton,
  Skeleton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import jwt_decode from 'jwt-decode';
/* Icon Imports */
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';
import HistoryRoundedIcon from '@mui/icons-material/HistoryRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import InfoRounded from '@mui/icons-material/InfoRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SystemUpdateRoundedIcon from '@mui/icons-material/SystemUpdateRounded';
import TelegramIcon from '@mui/icons-material/Telegram';
/* Local imports */
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import {
  openDrawer,
  closeDrawer,
  clearSession,
  setUpdateLater
} from '../../slices/sessionSlice';
import { clearForms, setStep, setFormTemplateId } from '../../slices/formSlice';
import { resetNonConformingAttempts } from '../../slices/answerMetadataSlice';
import api from '../../services/api';
import Cookies from 'js-cookie';
import theme from '../../theme';
import DialogPairedButtons from '../DialogPairedButtons';
import { useHistory } from 'react-router';
import useStyles from '../../useStyles';
import {
  useIsAwaitingToSync,
  useMutationCache
} from '../../hooks/voroMutation';
import { clearDB } from '../../services/syncDB';
import { useUserData } from '../../hooks/query.hooks';
import useAlert from '../../hooks/useAlert';
import SnackbarAlert from '../SnackbarAlert';
import { isIphone } from '../../utils/device';
import { getS3File } from '../../services/files';
import { setUpdateStatus } from '../../utils/misc';
import { forceAppUpdate } from '../../services/update';
import formSignerClient from '../../services/formSigner/formSignerClient';

const localUseStyles = makeStyles({
  drawerContent: {
    width: 256,
    color: theme.palette.grey[700],
    gap: 32
  },
  list: {
    padding: '16px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  itemIcon: {
    minWidth: 40,
    color: theme.palette.grey[600]
  },
  topBannerDiv: {
    margin: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: 16,
    gap: 12
  },
  photoContainerDiv: {
    height: 52,
    width: 52,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    border: '2px solid white'
  },
  circleMarker: {
    position: 'absolute',
    left: 15,
    top: 7,
    color: theme.palette.warning.main,
    fontSize: 12
  }
});

type DrawerMenuProps = {
  isLogged: boolean;
  confirmNavigation?: boolean;
};

export default function DrawerMenu(props: DrawerMenuProps) {
  let token = Cookies.get('token');
  /* Styles */
  const classes = useStyles(theme);
  const localClasses = localUseStyles();
  /* Hooks */
  const history = useHistory();
  const alertHook = useAlert();
  const dispatch = useAppDispatch();
  const drawerOpen = useAppSelector(state => state.session.drawerOpen);
  const updateStatus = useAppSelector(state => state.session.updateStatus);
  const updateLater = useAppSelector(state => state.session.updateLater);
  const mutationCache = useMutationCache();
  const awaitingAnswers = useIsAwaitingToSync(['addFormAnswer'])?.length ?? 0;
  const awaitingCancellations =
    useIsAwaitingToSync(['cancelFormAnswer'])?.length ?? 0;
  const debugMode = useAppSelector(state => state.session.debugMode);
  /* States */
  const [feedBack, setFeedBack] = useState('');
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [signOutConfirmationDialogOpen, setSignOutConfirmationDialogOpen] =
    useState(false);
  const [clearAnswersDialogOpen, setClearAnswersDialogOpen] = useState(false);
  const [queuedRoute, setQueuedRoute] = useState<string | null>(null);
  const [hasAccessToTutorials, setHasAccessToTutorials] =
    useState<boolean>(false);
  const [hasAccessToCertifications, setHasAccessToCertifications] =
    useState<boolean>(false);
  const [selfieBase64, setSelfieBase64] = useState<string | null>();
  const { data: userData, isQuerying } = useUserData({
    disabled: !props.isLogged,
    fetchOnlyOnEmptyCache: true
  });
  const displayName = useMemo(() => {
    if (userData) {
      return (
        userData?.name?.split(' ')[0] + ' ' + userData?.name?.split(' ')?.pop()
      );
    } else if (isQuerying) {
      return null;
    } else if (!userData) {
      return undefined;
    }
  }, [userData, isQuerying]);
  const initials = useMemo(() => {
    if (!!displayName) {
      const names = displayName.split(' ');
      const result = `${names[0][0]}${
        names[1] ? names[1][0] : ''
      }`.toUpperCase();
      return result;
    } else {
      return '';
    }
  }, [displayName]);
  /* Functions */
  const onClose = () => dispatch(closeDrawer());
  const onOpen = () => dispatch(openDrawer());
  const confirmSignOut = () => setSignOutConfirmationDialogOpen(true);
  const clearAllAndSignOut = async () => {
    dispatch(clearForms());
    dispatch(clearSession());
    dispatch(setStep(0));
    dispatch(setFormTemplateId(undefined));
    dispatch(resetNonConformingAttempts());
    formSignerClient.reset();
    api.abortAllRequests();
    Cookies.remove('token');
    Cookies.remove('name');
    Cookies.remove('username');
    Cookies.remove('email');
    await clearDB();
    onClose();
    history.push('/');
  };
  const sendTelegram = () => {
    api
      .post('/sendTelegram', { msg: feedBack })
      .then(res => {
        alertHook.info('Mensagem enviada');
        setOpenFeedback(false);
        setFeedBack('');
      })
      .catch(err => {
        alertHook.error('Erro ao enviar. Tente novamente mais tarde');
        setOpenFeedback(false);
        setFeedBack('');
      });
  };

  const activateUpdate = () => {
    if (!navigator.onLine) {
      alertHook.warning(
        'Não é possível atualizar enquanto você estiver offline.'
      );
    } else if ('serviceWorker' in navigator) {
      setLoadingUpdate(true);
      navigator.serviceWorker
        .getRegistration(`${window.location.origin}/service-worker.js`)
        .then(registration => {
          if (registration?.waiting)
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          else {
            alertHook.error('Erro! Nenhum service worker esperando.');
            setUpdateStatus('error');
          }
        })
        .catch(err => {
          console.error(err);
          alertHook.error(
            'Erro ao ativar nova atualização! Tente novamente mais tarde.'
          );
          setUpdateStatus('error');
        })
        .finally(() => {
          setLoadingUpdate(false);
          setOpenUpdate(false);
        });
    } else {
      setOpenUpdate(false);
      alertHook.error(
        'Service worker não encontrado! Tente novamente mais tarde.'
      );
      setUpdateStatus('error');
    }
  };

  const desiredButtonCallback = () => {
    setSignOutConfirmationDialogOpen(false);
  };
  const cancelNavCallback = () => {
    setClearAnswersDialogOpen(false);
    onClose();
  };
  const confirmNavCallback = () => {
    setClearAnswersDialogOpen(false);
    if (queuedRoute) history.push(queuedRoute);
    setQueuedRoute(null);
  };
  const secondaryButtonCallback = () => {
    setSignOutConfirmationDialogOpen(false);
    clearAllAndSignOut();
  };
  const navigate = (route: string) => {
    if (!props.confirmNavigation) {
      onClose();
      history.push(route);
    } else {
      setQueuedRoute(route);
      setClearAnswersDialogOpen(true);
      onClose();
    }
  };
  /* Use effects */
  useEffect(() => {
    // TODO: IMPLEMENT ACTUAL AUTHENTICATION!!!
    if (token) {
      let decoded: any = jwt_decode(token);
      const { id_company } = decoded;
      if (!(id_company in [6, 7])) {
        // adding BRF to the list
        setHasAccessToTutorials(true);
      }
      if (id_company in [1, 6, 7]) {
        // adding BRF to the list
        setHasAccessToCertifications(true);
      }
    }
  }, [token]);

  useEffect(() => {
    return () => {
      setFeedBack('');
      setOpenFeedback(false);
      setSignOutConfirmationDialogOpen(false);
      setClearAnswersDialogOpen(false);
      setQueuedRoute(null);
      setHasAccessToTutorials(false);
      setHasAccessToCertifications(false);
    };
  }, []);

  useEffect(() => {
    if (userData !== undefined) {
      if (!!userData.selfie?.bucketData) {
        getS3File(
          userData?.selfie?.bucketData?.key,
          userData?.selfie?.bucketData?.name,
          'base64'
        )
          .then(base64 => {
            if (typeof base64 === 'string') setSelfieBase64(base64);
            else setSelfieBase64(null);
          })
          .catch(err => {
            console.error(err);
            setSelfieBase64(null);
          });
      } else {
        setSelfieBase64(null);
      }
    }
  }, [userData]);

  useEffect(() => {
    setOpenUpdate(updateStatus === 'pending' && !updateLater);
  }, [updateStatus, updateLater]);

  return (
    <React.Fragment>
      <SwipeableDrawer
        anchor={'right'}
        open={drawerOpen}
        onClose={onClose}
        onOpen={onOpen}
      >
        <div className={localClasses.drawerContent}>
          <div className={localClasses.topBannerDiv}>
            <div className={localClasses.photoContainerDiv}>
              {selfieBase64 ? (
                <img src={selfieBase64 ?? ''} alt="" width="100%" />
              ) : (
                <Typography fontSize={26} fontWeight={600} color="primary">
                  {initials}
                </Typography>
              )}
            </div>

            <div>
              {displayName === undefined ? (
                <Skeleton style={{ backgroundColor: '#ffffff24' }} width={96} />
              ) : !!displayName ? (
                <b>{displayName}</b>
              ) : (
                <b>Olá</b>
              )}
              {isQuerying ? (
                <Skeleton
                  style={{ backgroundColor: '#ffffff24' }}
                  width={160}
                  height={17}
                />
              ) : !!userData?.email ? (
                <Typography variant="body2" fontWeight={500}>
                  {userData.email}
                </Typography>
              ) : (
                <Typography variant="body2" fontWeight={500}>
                  Seja bem vindo
                </Typography>
              )}
            </div>
          </div>
          <Divider />
          <List className={localClasses.list}>
            <ListItemButton
              id="li-home"
              onClick={() => navigate('/home')}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <HomeRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Página Principal'} />
            </ListItemButton>

            <ListItemButton
              id="li-history"
              onClick={() => navigate('/documents-history')}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <HistoryRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Histórico de Documentos'} />
            </ListItemButton>

            <ListItemButton
              onClick={() => navigate('/my-account')}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <PersonRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Minha Conta'} />
            </ListItemButton>

            {hasAccessToTutorials && (
              <ListItemButton
                onClick={() => navigate('/tutorial-select')}
                disabled={!props.isLogged}
              >
                <ListItemIcon className={localClasses.itemIcon}>
                  <MenuBookRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={'Tutoriais'} />
              </ListItemButton>
            )}

            {/* <ListItemButton
              onClick={() => navigate('/equipments')}
              disabled={!props.isLogged || true}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <BuildRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Meus EPIs'} />
            </ListItemButton> */}

            {hasAccessToCertifications && (
              <ListItemButton
                disabled={!props.isLogged}
                onClick={() => navigate('/certifications')}
              >
                <ListItemIcon className={localClasses.itemIcon}>
                  <SchoolRoundedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={'Minhas Certificações'} />
              </ListItemButton>
            )}

            <ListItemButton
              onClick={() => navigate('/qrScanner')}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <QrCodeScannerRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Leitor de Código QR'} />
            </ListItemButton>

            {/* <ListItem button onClick={() => { window.location.href = "/notifications" }}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary={"Notificações"} />
            </ListItem> */}
          </List>

          <Divider />

          <List className={localClasses.list}>
            {isIphone() && (
              <ListItemButton onClick={() => window.location.reload()}>
                <ListItemIcon sx={{ minWidth: 42 }}>
                  <ReplayRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={'Recarregar Página'} />
              </ListItemButton>
            )}

            <ListItemButton onClick={forceAppUpdate}>
              <ListItemIcon className={localClasses.itemIcon}>
                {updateStatus === 'pending' && (
                  <CircleRoundedIcon className={localClasses.circleMarker} />
                )}
                <SystemUpdateRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Atualizar Aplicativo'} />
            </ListItemButton>

            <ListItemButton onClick={() => navigate('/supportCenter')}>
              <ListItemIcon className={localClasses.itemIcon}>
                <SupportRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={`Resolução de Problemas`} />
            </ListItemButton>

            <ListItemButton onClick={() => navigate('/config')}>
              <ListItemIcon className={localClasses.itemIcon}>
                <SettingsRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Configurações'} />
            </ListItemButton>
          </List>

          <Divider />

          <List className={localClasses.list}>
            <ListItemButton
              onClick={() => navigate('/termos-e-condicoes-de-uso')}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <InfoRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Termos e Condições de Uso'} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setOpenFeedback(true);
                onClose();
              }}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <FeedbackRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Sugestões'} />
            </ListItemButton>

            <ListItemButton
              id="btn-drawer-logout"
              onClick={confirmSignOut}
              disabled={!props.isLogged}
            >
              <ListItemIcon className={localClasses.itemIcon}>
                <ExitToAppRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={'Sair'} />
            </ListItemButton>
          </List>
        </div>
      </SwipeableDrawer>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        slotProps={{
          backdrop: { className: classes.dialogBackdrop }
        }}
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
        fullWidth={true}
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ display: 'flex', gap: 8 }}
          variant="h3"
        >
          <TelegramIcon />
          Entre em contato
        </DialogTitle>
        <DialogContent
          style={{ display: 'flex', gap: 16, flexDirection: 'column' }}
        >
          <DialogContentText>
            Tem alguma dúvida, sugestão ou encontrou algum erro no aplicativo?{' '}
            <b>Escreva para nós!</b>
          </DialogContentText>
          <TextField
            multiline
            rows={8}
            fullWidth
            value={feedBack}
            variant="outlined"
            onChange={evt => setFeedBack(evt.target.value)}
          />
          <DialogActions style={{ padding: 0 }}>
            <Button
              color="primary"
              variant="contained"
              endIcon={<SendRoundedIcon />}
              onClick={sendTelegram}
            >
              Enviar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        slotProps={{ backdrop: { className: classes.dialogBackdrop } }}
        open={signOutConfirmationDialogOpen}
      >
        <DialogContent>
          <Typography variant="h6" align="left" style={{ marginBottom: 20 }}>
            {'Deseja mesmo sair?'}
          </Typography>
          <DialogContentText>
            {awaitingAnswers + awaitingCancellations === 0
              ? `
               Se você sair, qualquer preenchimento não salvo e dados locais serão perdidos. \n
               Tem certeza de que deseja sair do aplicativo?
              `
              : awaitingAnswers + awaitingCancellations === 1
              ? `${
                  awaitingAnswers + awaitingCancellations
                } formulário ainda não foi enviado. \n
                
                Se você sair agora, esse formulário será deletado e não será enviado.\n

                Tem certeza de que deseja sair do aplicativo?`
              : `${
                  awaitingAnswers + awaitingCancellations
                } formulários ainda não foram enviados. \n
                Se você sair agora, esses formulários serão deletados e não serão enviados.
                Tem certeza de que deseja sair do aplicativo?`}
          </DialogContentText>
          <DialogPairedButtons
            desiredButtonText={'Cancelar'}
            secondaryButtonText={'Sair'}
            desiredButtonCallback={desiredButtonCallback}
            secondaryButtonCallback={secondaryButtonCallback}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{ style: { borderRadius: 5 } }}
        open={clearAnswersDialogOpen}
        slotProps={{ backdrop: { className: classes.dialogBackdrop } }}
      >
        <DialogContent>
          <Typography variant="h6" align="left" style={{ marginBottom: 20 }}>
            {'Você tem certeza disso?'}
          </Typography>
          <DialogContentText>
            {'Se você sair desta página, essa APR não será enviada.'}
          </DialogContentText>
          <DialogPairedButtons
            desiredButtonText={'Permanecer'}
            secondaryButtonText={'Sair desta Página'}
            desiredButtonCallback={cancelNavCallback}
            secondaryButtonCallback={confirmNavCallback}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openUpdate}
        PaperProps={{ style: { borderRadius: 5 } }}
        slotProps={{ backdrop: { className: classes.dialogBackdrop } }}
      >
        <DialogContent style={{ padding: '8px 16px' }}>
          <Typography variant="h4" marginBottom={2} marginTop={2}>
            Nova atualização disponível!
          </Typography>
          <Typography marginBottom={2}>
            Você deseja atualizar o aplicativo agora?
          </Typography>
          <DialogPairedButtons
            desiredButtonText="Atualizar agora"
            secondaryButtonText="Mais tarde"
            desiredButtonCallback={activateUpdate}
            desiredButtonIcon={<SystemUpdateRoundedIcon />}
            desiredButtonIsLoading={loadingUpdate}
            secondaryButtonCallback={() => {
              setOpenUpdate(false);
              dispatch(setUpdateLater(true));
            }}
          />
        </DialogContent>
      </Dialog>
      <SnackbarAlert {...alertHook.state} />
    </React.Fragment>
  );
}
