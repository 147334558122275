import { makeStyles } from '@mui/styles';

const authenticatedUseStyles = makeStyles(theme => ({
  baseSignerButton: {
    height: '100%',
    minHeight: 80,
    width: '100%',
    textTransform: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16
  },
  buttonList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    gap: 16
  },
  sigBtnMainDiv: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    alignItems: 'flex-start'
  },
  successBtn: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.success.light,
      opacity: 0.5,
      color: 'black'
    }
  }
}));

export default authenticatedUseStyles;
