import axios from 'axios';
import dayjs from 'dayjs';
import db from '../services/db';
import {
  setDebugMode,
  setUpdateStatus as setUStatus
} from '../slices/sessionSlice';
import store from '../store';
import { UpdateStatus } from '../types/utils';
import { setAdvancedCamera } from '../slices/configSlice';
import { logDiscord } from '../services/logAppEvent';

export async function enableDebugMode() {
  store.dispatch(setDebugMode(true));
  await db.table('misc').put({ key: 'debugMode', value: true });
}

export async function disableDebugMode() {
  store.dispatch(setDebugMode(false));
  await db.table('misc').put({ key: 'debugMode', value: false });
}

export async function enableAdvancedCamera() {
  store.dispatch(setAdvancedCamera(true));
  await db.table('misc').put({ key: 'advancedCamera', value: true });
}

export async function disableAdvancedCamera() {
  store.dispatch(setAdvancedCamera(false));
  await db.table('misc').put({ key: 'advancedCamera', value: false });
}

export async function setUpdateStatus(status: UpdateStatus) {
  store.dispatch(setUStatus(status));
  await db.table('misc').put({ key: 'updateStatus', value: status });
}

export function formatDate(isodate?: string) {
  if (!isodate) return '';
  const now = new Date();
  const date = new Date(isodate);
  const sameMonth =
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();
  const isToday = now.getDate() === date.getDate() && sameMonth;
  const isYesterday = now.getDate() === date.getDate() + 1 && sameMonth;
  if (isToday) {
    return `de hoje às ${dayjs(isodate).format('HH:mm')}`;
  } else if (isYesterday) {
    return `de ontem às ${dayjs(isodate).format('HH:mm')}`;
  } else {
    return dayjs(isodate).format('DD/MM/YYYY - HH:mm');
  }
}

export function formatPhoneNumber(phone: string) {
  let len = phone.length;
  let formatted = phone;
  // formating...
  if (len > 0 && len <= 2) formatted = formatted.replace(/(\d{2})/, '($1');
  else if (len > 2 && len <= 6)
    formatted = formatted.replace(/(\d{2})(\d{1,4})/, '($1) $2');
  else if (len > 6 && len <= 10)
    formatted = formatted.replace(/(\d{2})(\d{4})(\d{1,4})/, '($1) $2 $3');
  else
    formatted = formatted.replace(
      /(\d{2})(\d{1})(\d{4})(\d{1,4})/,
      '($1) $2 $3 $4'
    );

  return formatted;
}

/**
 * Converts an amount of seconds into a time string in full
 * @param seconds time amount in seconds
 * @returns a string with the time in full
 * @authro Douglas Flores
 */
export function secondsToFullTime(seconds: number) {
  if (seconds < 60) return `${seconds} segundos`;
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds / 60) % 60);
  const sec = seconds - hours * 3600 - minutes * 60;
  let response = '';
  if (hours > 0) response += `${hours} hora${hours === 1 ? '' : 's'} `;
  if (minutes > 0)
    response += `${hours > 0 ? 'e ' : ''}${minutes} minuto${
      minutes === 1 ? '' : 's'
    } `;
  if (sec > 0)
    response += `${hours + minutes > 0 ? 'e ' : ''}${sec} segundo${
      sec === 1 ? '' : 's'
    }`;
  return response;
}

/**
 * Gets the city's name based on the gps coordinates
 * @param latitude self explanatory
 * @param longitude self explanatory
 * @param ulid_formAnswer formAnswer's id
 * @returns a string city on success
 * @author Douglas Flores
 */
export async function getCityName(
  latitude: string | number,
  longitude: string | number,
  ulid_formAnswer?: string
): Promise<string | undefined> {
  try {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;
    const res = await axios.get<any>(url, { timeout: 5000 });
    const city = res.data?.address?.city ?? res.data?.address?.town ?? '';
    console.log(city);
    if (typeof city === 'string') return city;
    else throw new Error('City name is not a string!');
  } catch (err) {
    console.log(err);
    await logDiscord(
      `Failed to determine city (${latitude}, ${longitude})${
        !!ulid_formAnswer ? ` for **formAnswer ${ulid_formAnswer}**` : ''
      }: ${err}`,
      undefined
    );
    return;
  }
}
