import { FormSignerErrorCode } from './types';

interface FormSignerInit {
  message: string; // english message to print or log
  code: FormSignerErrorCode; // the error's code
  userMessage?: string; // a message that can be displayed to the user
}
export class FormSignerError extends Error {
  code: FormSignerErrorCode;
  options?: { cause: unknown };
  userMessage?: string;
  constructor(params: FormSignerInit, options?: { cause: unknown }) {
    super(params.message);
    this.name = 'SignerError';
    this.code = params.code;
    this.options = options;
    this.userMessage = params?.userMessage;
  }
}
