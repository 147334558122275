/* Libraries imports */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import {
  InputBase,
  Dialog,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
  Zoom,
  Grow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  IconButton,
  Select,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';
import { ulid as generateUlid } from 'ulid';
/* Icons */
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThumbDownOffAltRoundedIcon from '@mui/icons-material/ThumbDownOffAltRounded';
/* Local imports */
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { resetNonConformingAttempts } from '../../slices/answerMetadataSlice';
import theme from '../../theme';
import useStyles from '../../useStyles';
import NavBar from '../../components/NavBar';
import { getCurrentPosition } from '../../services/getPos';
import { Photo } from '../../types/form';
import MainContainer from '../../components/MainContainer/MainContainer';
// import localUseStyles from './styles';
import PhotosInput from '../../components/PhotosInput';
import {
  useCompanyName,
  useFormTemplates,
  useCoworkers
} from '../../hooks/query.hooks';
import { useAddFormAnswer } from '../../hooks/mutation.hooks';
import Answer from '../../components/Answer';
import { formatCPF } from '../../utils/cpf';
import BioSignature from '../../components/BioSignature';
import FieldUser from '../FilloutForm/components/FieldUser';
import FieldText from '../FilloutForm/components/FieldText';
import FieldNumber from '../FilloutForm/components/FieldNumber';
import FieldMeasurement from '../FilloutForm/components/FieldMeasurement';
import FieldMiscMeasurements from '../FilloutForm/components/FieldMiscMeasurements';
import FieldBinaryQuestion from '../FilloutForm/components/FieldBinaryQuestion';
import FieldTernaryQuestion from '../FilloutForm/components/FieldTernaryQuestion';
import FieldCheckbox from '../FilloutForm/components/FieldCheckbox';
import FieldDate from '../FilloutForm/components/FieldDate';
import FieldPhoto from '../FilloutForm/components/FieldPhoto';
import { checkRequiredFields } from '../../utils/form';
import { version } from '../../version.json';
import { FormField } from '../../../vorotypes/types/formTemplate';
import { FAMutationSubmitPackage } from '../../../vorotypes/types/formAnswer';

export default function NonExecutionTriggered() {
  const classes = useStyles(theme);
  const [unique_id] = useState<string>(uuidv4());
  const [ulid] = useState<string>(generateUlid());
  /* Cookies */
  const token = Cookies.get('token');
  const creatorId = token && (JSON.parse(atob(token.split('.')[1]))?.id ?? 0);
  /* Hooks */
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isOnline = useAppSelector(state => state.session.online);
  const formTemplateId = useAppSelector(state => state.form.formTemplateId);
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  const nonConformingSubmissionAttempts = useAppSelector(
    state => state.answerMetadata.nonConformingSubmissionAttempts
  );
  const { data: formTemplates } = useFormTemplates({
    fetchOnlyOnEmptyCache: true
  });
  const { data: companyName } = useCompanyName();
  const { data: coworkersData } = useCoworkers({
    fetchOnlyOnEmptyCache: true
  });
  const answerInitDate = useAppSelector(
    state => state.answerMetadata.answerInitDate
  );
  const answerInitPosition = useAppSelector(
    state => state.answerMetadata.answerInitPosition
  );
  const risksRef = React.useRef<any>();
  const addFormAnswer = useAddFormAnswer(ulid);
  /* Const vars */
  const formTemplate = formTemplates?.find(ft => ft.id === formTemplateId);
  const formConfig = formTemplate?.template?.config;
  const coworkers = coworkersData?.coworkers ?? [];
  const creator = coworkers?.find(c => c.id === creatorId);
  /* States */
  const [authClearance, setAuthClearance] = useState<boolean>(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);
  /* NonExec Form */
  const fields: FormField[] = formTemplate?.template?.nonExecutionFields ?? [];
  const [answers, setAnswers] = useState<{ field_id: string; answer: any }[]>(
    []
  );
  /* Signature inputs */
  const [identifierType, setIdentifierType] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');
  const [identifierClean, setIdentifierClean] = useState<string>('');
  const [idPhotos, setIdPhotos] = useState<Photo[]>([]);
  const [signing, setSigning] = useState<boolean>(false);
  const [bioSignature, setBioSignature] = useState<string>('');
  /* Functions */
  const onSignatureClick = async () => {
    // Opening signature dialog
    setSigning(prev => !prev);
    if (
      document.fullscreenEnabled &&
      import.meta.env.REACT_APP_ORIENTATION !== 'false'
    ) {
      // Request change to fullscreen
      await document.documentElement.requestFullscreen();
      // Locking to landscape
      if (!!document.fullscreenElement)
        // @ts-ignore
        await window.screen.orientation.lock('landscape');
    }
  };
  const onCloseSigning = async () => {
    if (!!document.fullscreenElement) {
      await document.exitFullscreen();
      // Unlocking orientation
      window.screen.orientation.unlock();
    }
    setSigning(false);
  };
  const renderValue = (val: string) => {
    if (val !== '') return undefined;
    else
      return (
        <Typography color={theme.palette.grey[500]}>
          Escolha um identificador
        </Typography>
      );
  };
  const handleIdentifierType = (val: string) => {
    if (val === 'id') {
      // fill aliasId
      setIdentifier(creator?.aliasId ? (creator?.aliasId as string) : '');
    } else if (val === 'cpf') {
      if (!!creator?.cpf) {
        setIdentifier(formatCPF(creator.cpf as string));
        // identifierClean is the non-formatted CPF
        setIdentifierClean(creator.cpf);
      }
    }
    setIdentifierType(val);
  };
  const handleIdentifier = (val: string) => {
    if (identifierType === 'cpf') {
      setIdentifier(formatCPF(val));
      setIdentifierClean(val);
    } else if (identifierType === 'id') {
      setIdentifier(val);
    }
  };
  const cleanup = () => dispatch(resetNonConformingAttempts());
  const successCheckout = () => {
    setConfirmationDialogOpen(true);
    setTimeout(() => {
      cleanup();
      history.push('/home');
    }, 2 * 1000);
  };
  const submit = async () => {
    try {
      setIsSubmitting(true);
      const submissionDate = new Date().toISOString();
      if (!formTemplate)
        throw new Error('Não foi possível encontrar o template do formulário!');
      const misc = formTemplate?.misc;
      const config = formTemplate.template?.config;
      const persist = config?.persistent;
      /* Validating Data */
      let idType = ['id', 'cpf'].includes(identifierType)
        ? identifierType
        : undefined;
      if (!idType) throw new Error('Tipo de identificador pessoal inválido!');
      const personalId = {
        type: idType as 'id' | 'cpf',
        value: identifierType === 'cpf' ? identifierClean : identifier
      };
      if (!idPhotos?.length)
        throw new Error('Nenhuma foto de identificação informada!');
      /* Building data package */
      const pos = await getCurrentPosition();
      const metadata = {
        title: !!misc?.title ? misc?.title : persist ? 'PTR' : 'APR',
        app_version: version,
        timer: {
          answerInitDate: answerInitDate,
          answerFinishedDate: submissionDate
        },
        position: {
          answerInitPosition: answerInitPosition,
          answerFinishedPosition: pos
        },
        nonConformingSubmissionAttempts: nonConformingSubmissionAttempts,
        uuid: unique_id,
        ulid: ulid
      };
      let nonExecAnswer = {};
      answers?.forEach(ans =>
        Object.assign(nonExecAnswer, { [ans.field_id]: ans.answer })
      );
      const curr_answer: FAMutationSubmitPackage = {
        ulid: ulid,
        id_postedby: creatorId,
        answer: {
          items: formAnswer,
          metadata: metadata,
          signatures: [
            {
              id: creatorId,
              date: submissionDate,
              signatureImage: {
                id: `${new Date().toISOString().substring(0, 19)}-${`${
                  creatorId as string
                }
                `.padStart(7, '0')}`,
                base64: bioSignature,
                type: 'image/png'
              },
              photo: idPhotos[0],
              personalId: personalId
            }
          ]
        },
        id_formTemplate: formTemplate.id,
        id_formType: formTemplate.id_formType,
        template: formTemplate.template,
        nonExecAnswer: nonExecAnswer
      };

      /* Executing mutation */
      addFormAnswer.mutate(curr_answer, {
        onSuccess: successCheckout,
        onError: err => {
          throw err;
        }
      });
    } catch (err) {
      console.log(err);
      alert('Ocorreu um erro para completar o seu envio!');
    } finally {
      setIsSubmitting(false);
    }
  };
  /* Use Effects */
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setDeviceWidth(newWidth);
      console.log('updating width');
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  useEffect(() => {
    var enable = !!formConfig; // Assure that the confirm button will be disabled if the formConfig isn't loaded yet
    if (!!formConfig?.requireIndividualAuthToConfirm) {
      if (!!formConfig?.biodynamicSignature) {
        enable = bioSignature?.length > 0;
      }
      if (!!formConfig?.requirePersonalDataInput) {
        enable = enable && identifierType?.length > 0 && identifier?.length > 0;
      }
      if (!!formConfig?.requireSignaturePhoto) {
        enable = enable && idPhotos?.length === 1;
      }
    }
    setAuthClearance(enable);
    //eslint-disable-next-line
  }, [idPhotos, bioSignature, identifierType, identifier, formConfig]);
  /* Return */
  return (
    <React.Fragment>
      <NavBar
        isLogged
        returnButton
        returnFunction={history.goBack}
        headerTitle="Não Execução"
      />
      <MainContainer
        isOnline={isOnline}
        scrollable
        maxWidth={'xs'}
        bottomButtons={1}
      >
        <Grow in={true}>
          <div className={classes.bodyList} style={{ gap: 8 }} ref={risksRef}>
            <Typography
              variant="h2"
              marginTop={0}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <ThumbDownOffAltRoundedIcon style={{ fontSize: 24 }} />
              Registro de risco elevado
            </Typography>

            <Typography>Esta atividade não será executada.</Typography>
            {/* Review what was answered */}
            <Accordion
              disableGutters
              square
              variant="outlined"
              sx={{
                backgroundColor: 'rgba(0,0,0,0.04)',
                borderColor: theme.palette.primary.light,
                borderRadius: '5px',
                paddingLeft: '8px',
                minWidth: '100%',
                '::before': {
                  display: 'none'
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h3"
                  marginTop={0}
                  display="flex"
                  alignItems="flex-start"
                  gap={1}
                >
                  <DescriptionRoundedIcon style={{ fontSize: 20 }} />
                  Revisar respostas
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!!formTemplate && (
                  <Answer
                    template={formTemplate.template}
                    answer={formAnswer}
                  />
                )}
              </AccordionDetails>
            </Accordion>
            {/* Input new data to append at the form answer */}
            {!!fields?.length && (
              <div
                style={{
                  marginTop: 14,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'baseline',
                  width: '100%',
                  textAlign: 'justify',
                  gap: 18
                }}
              >
                {fields.map(field => {
                  switch (field?.type) {
                    case 'User':
                      return (
                        <FieldUser
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Users':
                      return (
                        <FieldUser
                          key={field.field_id}
                          multiselect
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Text':
                      return (
                        <FieldText
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Number':
                      return (
                        <FieldNumber
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Measurement':
                      return (
                        <FieldMeasurement
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'MiscMeasurements':
                      return (
                        <FieldMiscMeasurements
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'BinaryQuestion':
                      return (
                        <FieldBinaryQuestion
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'TernaryQuestion':
                      return (
                        <FieldTernaryQuestion
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'CheckBox':
                      return (
                        <FieldCheckbox
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Date':
                      return (
                        <FieldDate
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    case 'Photo':
                      return (
                        <FieldPhoto
                          key={field.field_id}
                          field={field}
                          setAnswers={setAnswers}
                        />
                      );
                    default:
                      return 'none';
                  }
                })}
              </div>
            )}

            <Divider />
            {/* Authentication inputs */}
            {formConfig?.requireIndividualAuthToConfirm && (
              <React.Fragment>
                <Typography
                  variant="h2"
                  marginTop={1}
                  display="flex"
                  gap={2}
                  alignItems="flex-end"
                >
                  <BadgeRoundedIcon style={{ fontSize: 30 }} />
                  Assinar Documento
                </Typography>
                <Typography variant="body2">
                  Ao assinar este documento você também está acordando com nossa{' '}
                  {/* <span
                  onClick={() => setTermsAndConditionsDialogOpen(true)}
                  className={classes.link}
                > */}
                  Política de Privacidade
                  {/* </span> */}.
                </Typography>

                {formTemplate?.template?.termsAndConditions && (
                  <React.Fragment>
                    <Typography variant="h3" marginBottom={0}>
                      Termos e Condições
                    </Typography>
                    <Typography variant="body2" textAlign="justify">
                      {formTemplate.template.termsAndConditions}
                    </Typography>
                  </React.Fragment>
                )}

                {formConfig?.biodynamicSignature && (
                  <React.Fragment>
                    <Typography variant="h3">Identificador</Typography>
                    <Typography variant="body2" fontWeight={500}>
                      Qual identificador você quer utilizar?
                    </Typography>
                    <Select
                      id="select-id"
                      fullWidth
                      displayEmpty
                      disableUnderline
                      variant="filled"
                      className={classes.selectRoot}
                      IconComponent={ArrowDropDownRoundedIcon}
                      MenuProps={{
                        classes: {
                          paper: classes.paperMenu,
                          list: classes.selectMenuList
                        },
                        style: { left: -2 }
                      }}
                      value={identifierType}
                      onChange={e => handleIdentifierType(e.target.value)}
                      renderValue={
                        identifierType !== '' ? undefined : renderValue
                      }
                    >
                      <MenuItem value="id" className={classes.menuItem}>
                        ID {companyName && companyName?.company_name}
                      </MenuItem>
                      <MenuItem value="cpf" className={classes.menuItem}>
                        CPF
                      </MenuItem>
                    </Select>
                    {!!identifierType && identifierType !== '' && (
                      <React.Fragment>
                        <Typography variant="body2" fontWeight={500}>
                          Insira seu identificador conforme selecionado acima:
                        </Typography>
                        <InputBase
                          id="txt-id"
                          fullWidth
                          className={classes.textInputBase}
                          value={identifier}
                          onChange={e => handleIdentifier(e.target.value)}
                        />
                      </React.Fragment>
                    )}
                    <Typography variant="h3">Foto</Typography>
                    <Typography variant="body2">
                      Tire uma foto sua para confirmar sua identidade.
                    </Typography>
                    <PhotosInput
                      single
                      photos={idPhotos}
                      onChange={photos => setIdPhotos(photos)}
                      coworkerId={creatorId}
                      height={88}
                    />
                    {!!bioSignature?.length && (
                      <React.Fragment>
                        <Typography variant="h3">Assinatura</Typography>
                        <img
                          src={bioSignature}
                          alt="assinatura"
                          width="100%"
                          style={{
                            border: '1px solid',
                            borderRadius: 10,
                            borderColor: theme.palette.primary.main
                          }}
                        />
                      </React.Fragment>
                    )}
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={onSignatureClick}
                      startIcon={<EditRoundedIcon style={{ fontSize: 16 }} />}
                    >
                      {!bioSignature?.length ? 'Assinar' : 'Refazer Assinatura'}
                    </Button>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </Grow>

        <div id="footer" className={classes.footer}>
          <LoadingButton
            className={classes.nextButton}
            variant="contained"
            onClick={submit}
            disabled={!(authClearance && checkRequiredFields(fields, answers))}
            color="error"
            loading={addFormAnswer.isMutating || isSubmitting}
            startIcon={<BlockRoundedIcon />}
          >
            Enviar
          </LoadingButton>
        </div>
      </MainContainer>
      <Dialog
        open={confirmationDialogOpen}
        BackdropProps={{ className: classes.dialogBackdrop }}
        PaperProps={{ style: { borderRadius: 5 } }}
      >
        <DialogContent>
          <Typography
            align="center"
            style={{ color: theme.palette.primary.main }}
          >
            <Zoom in={true} timeout={500}>
              <CheckRoundedIcon style={{ fontSize: '100px' }} />
            </Zoom>
          </Typography>
          <DialogContentText>{'Registrado com sucesso'}</DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={signing}
        BackdropProps={{ className: classes.dialogBackdrop }}
        PaperProps={{
          style: { backgroundColor: theme.palette.background.default }
        }}
        onClose={onCloseSigning}
        fullScreen
      >
        <div className={classes.termsAndConditionsNav}>
          <Typography fontWeight={600}>
            Assinar {formTemplate?.title}
          </Typography>
          <IconButton
            style={{ fontWeight: 400, padding: 2 }}
            onClick={onCloseSigning}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <div
          style={{
            padding: 10,
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
            alignItems: 'flex-end'
          }}
        >
          <BioSignature
            width={deviceWidth - 48}
            height={200}
            saveFormat="png"
            onSubmit={base64 => {
              setBioSignature(base64 || '');
              onCloseSigning();
            }}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
}
