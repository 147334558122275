/* Global imports */
import React, { useRef, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import {
  Dialog,
  DialogTitle,
  Divider,
  Drawer,
  Grow,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Slide,
  Typography
} from '@mui/material';
import clsx from 'clsx';
/* Icon imports */
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import AssignmentIndRoundedIcon from '@mui/icons-material/AssignmentIndRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import SearchIcon from '@mui/icons-material/Search';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
/* Local imports */
import fieldUserStyles from './styles';
import useStyles from '../../../../useStyles';
import {
  useCertifications,
  useCompanyName,
  useCoworkers,
  useVoroRoleDisplay
} from '../../../../hooks/query.hooks';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import theme from '../../../../theme';
import { Certification } from '../../../../../vorotypes/types/certification';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch } from 'react-redux';
import { popRoadBlocker, pushRoadBlocker } from '../../../../slices/formSlice';
import {
  FormField,
  FormTemplate,
  RequiredCertification
} from '../../../../../vorotypes/types/formTemplate';
import { Coworker } from '../../../../../vorotypes/types/user';
import FilterButtonList from './FilterButtonList';
import { FilterId } from './types';
import RenderCoworkers from './RenderCoworkers';
import AnswerSummary from './AnswerSummary';
import CertificationTab from './CertificationTab';
import useFilter from '../../../../hooks/useFilter';
import { VoroRoleDisplay } from '../../../../../vorotypes/types/authorization';
import RolesTab from './RolesTab';

type FieldUserProps = {
  field: FormField;
  setAnswers: React.Dispatch<
    React.SetStateAction<{ field_id: string; answer: any }[]>
  >;
  answers?: { field_id: string; answer: any }[];
  localFields?: FormField[];
  multiselect?: boolean;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FieldUser(props: FieldUserProps) {
  const classes = useStyles();
  const { field, setAnswers, multiselect, answers } = props;
  const localClasses = fieldUserStyles();
  const token = Cookies.get('token');
  const creatorId = token && (JSON.parse(atob(token.split('.')[1]))?.id ?? 0);
  /* ------------------------------------------------------------------------
   * --- Hooks --------------------------------------------------------------
   * ------------------------------------------------------------------------ */
  const { data: coworkersList, isLoading: isLoadingCoworkers } = useCoworkers({
    fetchOnlyOnEmptyCache: true
  });
  const { data: certificationsList } = useCertifications({
    fetchOnlyOnEmptyCache: true
  });
  const { data: voroRoleDisplayList } = useVoroRoleDisplay({
    fetchOnlyOnEmptyCache: true
  });
  const { data: companyName } = useCompanyName({ fetchOnlyOnEmptyCache: true });
  const dispatch = useDispatch();
  const formAnswer = useAppSelector(state => state.form.formAnswer);
  const formTemplate = useAppSelector(state => state.form.formTemplate);
  const globalRequiredCertifications = useAppSelector(
    state => state.form.requiredCertifications
  );
  const localRequiredCertifications =
    globalRequiredCertifications?.filter(r =>
      r.field_ids.includes(field.field_id)
    ) ?? [];

  /* ------------------------------------------------------------------------
   * --- States -------------------------------------------------------------
   * ------------------------------------------------------------------------ */
  const [userFields, setUserFields] = useState<FormField[]>([]);
  const [listedCoworkers, setListedCoworkers] = useState<Coworker[]>([]);
  const [selectedCoworkers, setSelectedCoworkers] = useState<number[]>([]);
  const [disabledCoworkers, setDisabledCoworkers] = useState<number[]>();
  /* --- filters --- */
  function filterByText(coworker: Coworker, search: string) {
    // replace accented characters into their base character
    const searchText = search
      ?.toLowerCase()
      ?.normalize('NFKD')
      ?.replace(/[\u0300-\u036f]/g, '');

    return (
      `${coworker?.name ?? ''}${coworker?.cpf ?? ''}${coworker.email ?? ''}`
        ?.toLowerCase()
        ?.normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        ?.includes(searchText) ?? false
    );
  }
  function filterByCertifications(
    coworker: Coworker,
    certifications: Array<Certification>
  ) {
    return (
      coworker?.certifications?.some(c =>
        certifications.find(cert => cert.id === c.id)
      ) ?? false
    );
  }
  function filterByVoroRoles(
    coworker: Coworker,
    voroRoles: Array<VoroRoleDisplay>
  ) {
    return voroRoles.some(vr => vr.ulid === coworker.ulid_voroRole);
  }
  const [filters, dispatchFilters, results] =
    useFilter<Coworker>(listedCoworkers);
  const [filterTab, setFilterTab] = useState<null | FilterId>(null);
  /* --- Control States --- */
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  /* --- Refs  --- */
  const listRef = useRef<any>(null);
  const topListRef = useRef<any>(null);
  /* ------------------------------------------------------------------------
   * --- Functions ----------------------------------------------------------
   * ------------------------------------------------------------------------ */
  function handleListItemClick(id: number) {
    if (selectedCoworkers.includes(id))
      setSelectedCoworkers(selectedCoworkers.filter(item => item !== id));
    else if (!multiselect) {
      setSelectedCoworkers([id]);
      setOpenDialog(false);
    } else setSelectedCoworkers(selectedCoworkers.concat(id));
  }
  function validateAnswer(answers: { field_id: string; answer: any }[]) {
    /* Getting selected users across this step */
    // Getting the ids of all selected users across this step
    const formSelectedUsersIds = userFields
      .flatMap(
        f => answers?.find(ans => ans.field_id === f.field_id)?.answer ?? []
      )
      .filter(f => !!f);
    // Getting the users based on the ids
    const formSelectedUsers =
      coworkersList?.coworkers?.filter(c =>
        formSelectedUsersIds.includes(c.id)
      ) ?? [];

    /* Getting the restrictions that are pending */
    const pendingRestriction = localRequiredCertifications
      .filter(
        r => r.composition === 'some' && r.field_ids.includes(field.field_id)
      )
      .find(
        r =>
          !formSelectedUsers.some(
            user =>
              user?.certifications?.some(
                c =>
                  c.id === r?.certification_id &&
                  new Date(c.validThrough) > new Date()
              )
          )
      );
    /* Managing road blockers based on the pending restrictions */
    if (!!pendingRestriction) {
      let involvedFieldsString: Array<string> = [];
      pendingRestriction.field_ids?.forEach((id: string, idx: number) => {
        const name =
          userFields.find(
            uf => uf.field_id === id && answers?.find(ans => ans.field_id)
          )?.properties?.question ?? '';
        if (!!name?.length) involvedFieldsString.push(name);
      });
      dispatch(
        pushRoadBlocker({
          field_id: field.field_id,
          snackbarInfo: {
            message: `Alguém dos campos ${involvedFieldsString} precisa ter a certificação ${certificationsList?.find(
              cert => cert.id === pendingRestriction.certification_id
            )?.name}`,
            severity: 'error'
          }
        })
      );
    } else {
      dispatch(popRoadBlocker(field.field_id));
    }
  }
  function updateAnswers() {
    setAnswers((prev: { field_id: string; answer: any }[]) => {
      let setValue = [];
      const payload: { field_id: string; answer: any } = {
        field_id: field.field_id,
        answer: [...selectedCoworkers]
      };
      // If there is no previous data in answers, set it with the current selectedCoworkers
      if (!prev) setValue = [payload];
      else {
        // Getting previous answer
        const prevAnswer = prev?.findIndex(
          (ans: any) => ans.field_id === field.field_id
        );
        // If a previous answer were found...
        if (prevAnswer > -1) {
          prev[prevAnswer] = {
            ...prev[prevAnswer],
            answer: selectedCoworkers
          };
          setValue = [...prev];
        } else {
          setValue = [
            ...prev,
            {
              field_id: field.field_id,
              answer: selectedCoworkers
            }
          ];
        }
      }
      // Re-validating answer
      if (!!formTemplate) validateAnswer(setValue);
      return setValue;
    });
  }
  function closeDialog() {
    dispatchFilters({ type: 'clear_filters' });
    setOpenDialog(false);
  }
  /* --- filter functions --- */
  function onClickCertification(certification: Certification) {
    const filter = filters['certifications'];
    const value: Certification[] = filter?.value;
    /* Checking if the target certification is already selected or not */
    const selected = Array.isArray(value)
      ? value?.find(v => v.id === certification.id)
      : undefined;
    /* Building new value for set_filter */
    const payload = !!selected // if selected, remove it
      ? value.filter(v => v.id !== certification.id)
      : !!value // if not selected and there is any other certification selected, add it
      ? [...value, certification]
      : [certification]; // if there is no certification selected, add to new array

    /* Removing filter on empty payload */
    if (!payload || payload.length === 0) {
      dispatchFilters({
        type: 'unset_filter',
        idFilter: 'certifications'
      });
      /* Updating filter */
    } else {
      dispatchFilters({
        type: 'set_filter',
        idFilter: 'certifications',
        payload: {
          fn: filterByCertifications,
          value: payload
        }
      });
    }
  }
  function onClickVoroRole(voroRole: VoroRoleDisplay) {
    const filter = filters['roles'];
    const value: VoroRoleDisplay[] = filter?.value;
    /* Checking if the target voroRole is already selected or not */
    const selected = Array.isArray(value)
      ? value?.find(v => v.ulid === voroRole.ulid)
      : value;
    /* Building new value for set_filter */
    const payload = !!selected // if selected, remove it
      ? value.filter(v => v.ulid !== voroRole.ulid)
      : !!value // if not selected and there is any other voroRole selected, add it
      ? [...value, voroRole]
      : [voroRole]; // if there is no voroRole selected, add to new array

    /* Removing filter on empty payload */
    if (!payload || payload.length === 0) {
      dispatchFilters({
        type: 'unset_filter',
        idFilter: 'roles'
      });
      /* Updating filter */
    } else {
      dispatchFilters({
        type: 'set_filter',
        idFilter: 'roles',
        payload: {
          fn: filterByVoroRoles,
          value: payload
        }
      });
    }
  }
  function initFilters() {
    const preFilters = field?.properties?.preFilters;
    if (!preFilters || !Array.isArray(preFilters) || preFilters.length === 0)
      return undefined;
    preFilters.forEach(f => {
      const filterAction = {
        type: 'set_filter' as any,
        idFilter: f.id,
        payload: {
          fn: filterByVoroRoles,
          value: [
            {
              name_: 'manager',
              ulid: '01HG911YM6J32AH0J8P88BSJBQ'
            }
          ]
        }
      };
      dispatchFilters(filterAction);
    });
  }
  /* ------------------------------------------------------------------------
   * --- useEffects ---------------------------------------------------------
   * ------------------------------------------------------------------------ */
  useEffect(() => {
    const reqInit =
      field?.field_id === 'approver' ||
      field.properties.initialValue === 'leader';
    const initialValue = reqInit && !!creatorId ? [creatorId] : [];
    setSelectedCoworkers(formAnswer[field.field_id] ?? initialValue);
    initFilters();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const userFields =
      props?.localFields?.filter(field =>
        ['User', 'Users'].includes(field?.type ?? '')
      ) ?? [];
    setUserFields([...userFields]);
  }, [props?.localFields]);
  // eslint-disable-next-line
  useEffect(updateAnswers, [selectedCoworkers]);
  useEffect(() => {
    if (!!formTemplate && !!coworkersList) {
      const alreadySelected = getUnavailableUsers(
        field,
        formTemplate,
        formAnswer,
        answers
      );
      let updatedListedCoworkers =
        coworkersList?.coworkers.filter(
          p => !alreadySelected?.includes(p.id)
        ) ?? [];
      setListedCoworkers(updatedListedCoworkers);
      /* Getting coworkers that should be disabled */
      const disabledUsers = getDisabledUsers(
        updatedListedCoworkers,
        localRequiredCertifications
      );
      setDisabledCoworkers(disabledUsers);
      /* Removing disabled coworker from current answer */
      if (
        !!selectedCoworkers?.length &&
        selectedCoworkers.some(sp => disabledUsers.includes(sp))
      ) {
        setSelectedCoworkers(prev =>
          prev.filter(p => !disabledUsers?.includes(p))
        );
      }
    }
    if (!!answers) validateAnswer(answers);
    // eslint-disable-next-line
  }, [answers, field, formAnswer, formTemplate, coworkersList]);

  /* --- Return --- */
  return (
    <div className={localClasses.container}>
      <div className={localClasses.fieldTitle}>
        {multiselect ? (
          <GroupsRoundedIcon style={{ fontSize: 22 }} />
        ) : (
          <AssignmentIndRoundedIcon style={{ fontSize: 22 }} />
        )}
        <Typography variant="h3" marginTop={0}>
          {field.properties.question}
        </Typography>
      </div>
      {field?.properties?.question !== field?.properties?.description && (
        <Typography marginTop={1} textAlign="left" whiteSpace="pre-line">
          {field?.properties?.description}
        </Typography>
      )}
      <RenderCoworkers
        coworkers={coworkersList?.coworkers?.filter(c =>
          selectedCoworkers.includes(c.id)
        )}
        onClickItem={
          field.properties?.disabled ? undefined : handleListItemClick
        }
      />
      {!(!multiselect && selectedCoworkers.length > 0) && (
        <Button
          id={`btn-add-coworker-${field.field_id}`}
          fullWidth
          variant="outlined"
          onClick={() => setOpenDialog(true)}
          startIcon={<PersonAddAltOutlinedIcon style={{ fontSize: 18 }} />}
          disabled={field?.properties?.disabled}
        >
          {multiselect ? 'Adicionar' : 'Selecionar'}
        </Button>
      )}
      <Dialog
        fullScreen
        open={openDialog}
        aria-labelledby="simple-dialog-title"
        onClose={closeDialog}
        style={{ zIndex: 1100 }}
        TransitionComponent={Transition}
      >
        <div id="header" className={localClasses.header}>
          <Typography
            variant="h3"
            display="flex"
            gap={4}
            marginTop={0}
            padding={'16px 8px 8px 16px'}
          >
            <PersonAddRoundedIcon style={{ fontSize: 22 }} />
            {`${multiselect ? 'Adicionar' : 'Selecionar'} ${
              field.properties.question
            }`}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseRoundedIcon />
          </IconButton>
        </div>

        <div className={localClasses.searchRow}>
          <div className={localClasses.searchBox}>
            <SearchIcon style={{ fontSize: 18 }} />
            <InputBase
              id="txt-input-search-participant"
              placeholder={`Buscar por nome${
                companyName?.company_name
                  ? `, CPF ou id ${companyName.company_name}`
                  : ' ou CPF'
              }`}
              className={localClasses.searchInput}
              inputProps={{ 'aria-label': 'search' }}
              onChange={e =>
                dispatchFilters({
                  type: 'set_filter',
                  idFilter: 'text',
                  payload: {
                    fn: filterByText,
                    value: e.target.value
                  }
                })
              }
            />
          </div>
          <IconButton
            id="btn-expand-filters"
            style={{
              borderRadius: 5,
              transition: 'background-color 500ms, color 500ms'
            }}
            className={
              Object.keys(filters)?.some(f => f !== 'text')
                ? localClasses.selectedFilter
                : localClasses.unselectedFilter
            }
            disableRipple
            onClick={() => setOpenFilters(prev => !prev)}
          >
            <TuneRoundedIcon />
            {Object.keys(filters)?.some(f => f !== 'text') && (
              <div className={localClasses.warningBall} />
            )}
          </IconButton>
        </div>

        <List
          className={localClasses.userList}
          onScroll={() => setIsVisible(isInViewport(topListRef, 0))}
          ref={listRef}
        >
          {field.type === 'Users' && (
            <AnswerSummary
              coworkers={coworkersList?.coworkers?.filter(p =>
                selectedCoworkers.includes(p.id)
              )}
              disabled={field.properties.disabled}
              ref={topListRef}
              onClickItem={handleListItemClick}
            />
          )}
          {isLoadingCoworkers && (
            <ListItem>
              <ListItemText primary={'Carregando...'} />
            </ListItem>
          )}
          {!!results &&
            results.filter(c => coworkersList?.recent?.includes(c.id)).length >
              0 && (
              <React.Fragment>
                <ListSubheader
                  disableSticky
                  className={localClasses.userListSubheader}
                >
                  Usados recentemente:
                </ListSubheader>
                {results
                  .filter(c => coworkersList?.recent?.includes(c.id))
                  .map((coworker, idx, arr) => (
                    <React.Fragment key={coworker.id}>
                      <ListItemButton
                        key={coworker.id}
                        className={localClasses.coworkerDialogItem}
                        selected={selectedCoworkers.includes(coworker.id)}
                        onClick={() => handleListItemClick(coworker.id)}
                        disabled={
                          !!disabledCoworkers
                            ? disabledCoworkers.includes(coworker.id)
                            : true
                        }
                      >
                        <div className={localClasses.coworkerDetails}>
                          <Typography fontWeight={600} fontSize={16}>
                            {coworker.name}
                          </Typography>
                          {coworker?.aliasId && (
                            <Typography
                              variant="body2"
                              color={theme.palette.grey[600]}
                            >
                              {`ID ${companyName?.company_name}: ${coworker.aliasId}`}
                            </Typography>
                          )}
                          {!!coworker.certifications?.length && (
                            <div
                              className={localClasses.certificationsContainer}
                            >
                              {coworker.certifications
                                .filter(certification => {
                                  return globalRequiredCertifications?.some(
                                    requiredCertification => {
                                      return (
                                        certification.id ===
                                        requiredCertification.certification_id
                                      );
                                    }
                                  );
                                })
                                .map(certification => {
                                  const currentCertification =
                                    certificationsList?.find(
                                      (c: any) => c.id === certification.id
                                    );
                                  const warningDate = new Date();
                                  warningDate.setDate(
                                    warningDate.getDate() +
                                      (currentCertification?.warningPeriodInDays ||
                                        0)
                                  );
                                  const warningDateString = warningDate
                                    .toISOString()
                                    .slice(0, 10);
                                  return (
                                    <div
                                      id={`tag-coworker-${idx}-certification-${certification.id}`}
                                      key={`user${coworker.id}-cert${certification.id}`}
                                      className={localClasses.certificationTag}
                                      style={{
                                        backgroundColor:
                                          certification?.validThrough <
                                          warningDateString
                                            ? '#AB4444'
                                            : 'rgba(20, 20, 60, 0.1)',
                                        color:
                                          certification?.validThrough <
                                          warningDateString
                                            ? '#fff'
                                            : theme.palette.primary.main
                                      }}
                                    >
                                      {certification.name}
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                        {selectedCoworkers.includes(coworker.id) && (
                          <CloseRoundedIcon
                            style={{ fontSize: 18, marginRight: 10 }}
                            color="primary"
                          />
                        )}
                      </ListItemButton>
                      {idx < arr.length - 1 && (
                        <Divider className={localClasses.divider} />
                      )}
                    </React.Fragment>
                  ))}
                <ListSubheader
                  disableSticky
                  className={localClasses.userListSubheader}
                >
                  Outros usuários:
                </ListSubheader>
              </React.Fragment>
            )}
          {results
            ?.filter(c => !coworkersList?.recent?.includes(c.id))
            ?.map((coworker, idx, arr) => (
              <React.Fragment key={coworker.id}>
                <ListItemButton
                  key={coworker.id}
                  className={localClasses.coworkerDialogItem}
                  selected={selectedCoworkers.includes(coworker.id)}
                  onClick={() => handleListItemClick(coworker.id)}
                  disabled={
                    !!disabledCoworkers
                      ? disabledCoworkers.includes(coworker.id)
                      : true
                  }
                >
                  <div className={localClasses.coworkerDetails}>
                    <Typography fontWeight={600} fontSize={16}>
                      {coworker.name}
                    </Typography>
                    {coworker?.aliasId && (
                      <Typography
                        variant="body2"
                        color={theme.palette.grey[600]}
                      >
                        {`ID ${companyName?.company_name}: ${coworker.aliasId}`}
                      </Typography>
                    )}
                    {!!coworker.certifications?.length && (
                      <div className={localClasses.certificationsContainer}>
                        {coworker.certifications
                          .filter(certification => {
                            return globalRequiredCertifications?.some(
                              requiredCertification => {
                                return (
                                  certification.id ===
                                  requiredCertification.certification_id
                                );
                              }
                            );
                          })
                          .map(certification => {
                            const currentCertification =
                              certificationsList?.find(
                                (c: any) => c.id === certification.id
                              );
                            const warningDate = new Date();
                            warningDate.setDate(
                              warningDate.getDate() +
                                (currentCertification?.warningPeriodInDays || 0)
                            );
                            const warningDateString = warningDate
                              .toISOString()
                              .slice(0, 10);
                            return (
                              <div
                                id={`tag-coworker-${idx}-certification-${certification.id}`}
                                key={`user${coworker.id}-cert${certification.id}`}
                                className={localClasses.certificationTag}
                                style={{
                                  backgroundColor:
                                    certification.validThrough <
                                    warningDateString
                                      ? '#AB4444'
                                      : 'rgba(20, 20, 60, 0.1)',
                                  color:
                                    certification.validThrough <
                                    warningDateString
                                      ? '#fff'
                                      : theme.palette.primary.main
                                }}
                              >
                                {certification.name}
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                  {selectedCoworkers.includes(coworker.id) && (
                    <CloseRoundedIcon
                      style={{ fontSize: 18, marginRight: 10 }}
                      color="primary"
                    />
                  )}
                </ListItemButton>
                {idx < arr.length - 1 && (
                  <Divider className={localClasses.divider} />
                )}
              </React.Fragment>
            ))}
        </List>

        <Grow in={!isVisible} mountOnEnter unmountOnExit>
          <IconButton
            style={{
              position: 'absolute',
              bottom: 80,
              left: '50%',
              transform: 'translateX(-50%)',
              boxShadow: '1px 1px 6px 0px rgba(0,0,0,0.25)',
              backgroundColor: 'white'
            }}
            onClick={() => {
              if (topListRef.current) {
                const y =
                  topListRef.current.getBoundingClientRect().top +
                  listRef.current.scrollY +
                  -10;
                listRef.current.scrollTo({ top: y, behavior: 'smooth' });
              }
            }}
          >
            <ArrowUpwardRoundedIcon />
          </IconButton>
        </Grow>

        {multiselect && (
          <Button
            id="btn-confirm-participant"
            variant="contained"
            className={clsx(classes.nextButton)}
            style={{ marginTop: 10, marginBottom: 10 }}
            onClick={closeDialog}
          >
            Confirmar
          </Button>
        )}
      </Dialog>
      <Drawer
        anchor="bottom"
        open={openFilters}
        onClose={() => setOpenFilters(false)}
        style={{ zIndex: 1200 }}
        PaperProps={{ style: { maxHeight: '80%', minHeight: '66%' } }}
      >
        <div className={localClasses.header}>
          <DialogTitle
            className={clsx(classes.subtitle, localClasses.dialogTitle)}
            style={{ marginTop: 0 }}
          >
            {!filterTab ? (
              <TuneRoundedIcon style={{ fontSize: 22 }} />
            ) : (
              <IconButton
                disableRipple
                style={{ padding: 1 }}
                onClick={() => setFilterTab(null)}
              >
                <ArrowBackRoundedIcon
                  style={{ fontSize: 20 }}
                  color="primary"
                />
              </IconButton>
            )}
            {filterTab === 'certifications'
              ? 'Certificações'
              : filterTab === 'roles'
              ? 'Cargo'
              : 'Filtros'}
          </DialogTitle>
          <IconButton
            onClick={() => {
              setOpenFilters(false);
              setFilterTab(null);
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        {!filterTab ? (
          <div className={localClasses.filterList}>
            <FilterButtonList
              filters={filters}
              onClickItem={(id: string) => setFilterTab(id as FilterId)}
            />
          </div>
        ) : filterTab === 'certifications' ? (
          <CertificationTab
            certifications={certificationsList}
            filter={filters['certifications']}
            onClickItem={onClickCertification}
          />
        ) : filterTab === 'roles' ? (
          <RolesTab
            voroRoles={voroRoleDisplayList}
            filter={filters['roles']}
            onClickItem={onClickVoroRole}
          />
        ) : (
          <div>No tab</div>
        )}
      </Drawer>
    </div>
  );
}

/**
 * Gets the ids of all users that must not be selected in a determined field
 * @param current_field the field that must decide which users to show available for selection
 * @param formTemplate the template of the form
 * @param formAnswer the formSlice's answer to the form
 * @param answers the local answers array from the current step
 * @returns a list of user ids that were already selected in other "user" inputs in this form
 */
function getUnavailableUsers(
  current_field: FormField,
  formTemplate: FormTemplate,
  formAnswer: any,
  answers?: { field_id: string; answer: any }[]
): Array<number> {
  const field = current_field;
  // Gets all user fields that exists in the formTemplate
  const allUserFields = formTemplate?.template?.steps
    .flatMap(step =>
      step.fields.filter(field => ['User', 'Users'].includes(field?.type ?? ''))
    )
    .filter(field => !!field);
  // Gets the ids of all fields in this step. This is used later to get full field object of all "user" fields of this step
  const localFieldIds = answers?.map(ans => ans.field_id);
  // Gets all "user" fields in this steps
  const localUserFields = allUserFields?.filter(
    usr_field =>
      localFieldIds?.includes(usr_field.field_id) &&
      field.field_id !== usr_field.field_id
  );
  // Gets all "user" fields outside of this step
  const foreignUserFields = allUserFields?.filter(
    usr_field => !localFieldIds?.includes(usr_field.field_id)
  );
  /* After distinguishing the "user" fields of this step and the ones outside it, we can treat them differently
   * The result array containing the users that are filtered is composed by:
   *  - The users selected in userFields outside this step, gattering their values from formSlice's formAnswer
   *  - The users selected in other userFields inside this step
   * This differentiation is needed because we must update the list of available users when any input in
   * this step is changed, even before the user clicks "next" and submit the input to the formSlice. So, the data
   * containing the answers to the other user fields in this step must come from the "answers" array of this same
   * step, ensuring that the available users keeps updated
   */
  return [
    ...(foreignUserFields?.flatMap(
      field => formAnswer[field?.field_id] ?? []
    ) ?? []),
    ...(localUserFields
      ?.flatMap(
        field => answers?.find(ans => ans.field_id === field.field_id)?.answer
      )
      .filter(usr => !!usr) ?? [])
  ];
}

/**
 * Gets the ids of all users that must not be enabled for selection in a determined field
 * @param users all users
 * @param requiredCertifications the list of required certifications
 * @returns a list of user ids that must be disabled at selection
 */
function getDisabledUsers(
  users: Coworker[],
  requiredCertifications: RequiredCertification[]
) {
  // Gets all certification's ids that are required
  const restrictions = requiredCertifications.filter(
    rc => rc.composition === 'every'
  );

  // Gets all users that doesnt meet the requirements
  const disabledUsers = users.filter(
    user =>
      restrictions?.some(
        r =>
          user.certifications?.every(
            c =>
              c.id !== r?.certification_id ||
              new Date(c.validThrough) < new Date()
          )
      )
  );
  // return the users that arent available
  return disabledUsers.map(u => u.id);
}

/**
 * Checks if a component is in the viewport
 * @param ref a useRef to an HTML/React Component
 * @param offset an offset from the top of the window
 * @returns true if the ref is in the viewport and false otherwise
 */
function isInViewport(ref: React.MutableRefObject<any>, offset = 0) {
  if (!ref.current) return false;
  const top = ref.current.getBoundingClientRect().top;
  return top + offset >= 0 && top - offset <= window.innerHeight;
}
