import { configureStore } from '@reduxjs/toolkit';
import formReducer from './slices/formSlice';
import sessionReducer from './slices/sessionSlice';
import answerMetadataReducer from './slices/answerMetadataSlice';
import tempPhotoReducer from './slices/tempPhotoSlice';
import registerReducer from './slices/registerSlice';
import configReducer from './slices/configSlice';
import formSignerReducer from './services/formSigner/slice';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    form: formReducer,
    answerMetadata: answerMetadataReducer,
    tempPhoto: tempPhotoReducer,
    register: registerReducer,
    config: configReducer,
    formSigner: formSignerReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
