import { makeStyles } from '@mui/styles';
import theme from '../../theme';

const localStyles = makeStyles(() => ({
  linkShareContainer: {
    display: 'flex',
    width: 214,
    justifyContent: 'space-between',
    borderRadius: 5,
    border: '1px solid rgba(0,0,0,0.15)',
    padding: 0,
    alignItems: 'center'
  },
  copyButton: {
    borderRadius: '0px 5px 5px 0px',
    padding: '4px 16px',
    width: 'fit-content',
    minWidth: 30,
    boxShadow: 'none',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      boxShadow: 'none'
    }
  },
  dialogContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: 2
  },
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2
  }
}));

export default localStyles;
